import React, {Component} from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faApple, faAndroid} from '@fortawesome/free-brands-svg-icons'
import apps from './our_apps'

class AppList extends Component {

  constructor(props) {
    super(props);

    this.state = {
      showHidden: false
    }
  }

  render() {
    const { showHidden } = this.state;
    
    const content = apps.map(app => {
    
      const html = (!app.hidden || showHidden) && (
        <a href={`/apps/${app.url}`} className="AppContainer">
            <img src={`/${app.url}.png`} alt={app.name} />
            <div>
              {app.name}
              <div className="icons">
                { app.ios && (<FontAwesomeIcon icon={faApple} alt="iOS" className="platformIcon" />)}
                { app.android && (<FontAwesomeIcon icon={faAndroid} alt="Android" className="platformIcon" />)}
              </div>
            </div>
          </a>
      );
      return html;
    });
  
    return (<div className="AppContainerContainer">
      <h2 style={{width: "100%"}}>Our Apps</h2>
      {content}
      { !showHidden && (<div class="showmore" onClick={() => this.setState({
        showHidden: true
        })}>
          Show More Apps
        </div>)
      }
    {/* <h2 style={{width: "100%"}}>Coming Soon</h2> */}
    </div>);
  }
}

export default AppList;