import React from 'react';

// list of apps and URLs (icons and relative to this page)
const apps = [
  {
    name: "Whoops!!",
    url: "whoops",
    ios: "https://itunes.apple.com/us/app/whoops/id1289150061",
    android: "https://play.google.com/store/apps/details?id=com.durian.whoops",
    bio: (<div><p>This isn't your average board game! Whoops!! brings the fun of timeless "cross and circle" games to your tablet or phone. <a href="/rules/whoops">Click here for rules.</a></p>
    <p>Move around the board clockwise and try to get all four of your pieces into the ending area. Different cards influence your movement and enable you to perform special moves that can quickly turn the tides on your opponents. But be careful! Your opponents have access to the same set of moves.</p>
    <p>Gameplay is rapid-paced, as cards that contain invalid moves will slide off-screen automatically. No time is wasted in-between turns. Progress bars and ranks in the four corners of the screen will keep you up to date on where you stand in comparison to the other players.</p>
    <p>Your pieces have personality as well! Watch as they react to the events that occur around them whenever you make a move. Special cards can also activate cutscenes featuring your pieces going head-to-head with opponents!</p></div>)
  },
  {
    name: "Think Big",
    url: "thinkbig",
    ios: "https://itunes.apple.com/us/app/thinkbig/id1452073393",
    android: "https://play.google.com/store/apps/details?id=com.durianapps.landgrab",
    bio: (<div><p>Think Big is a game that challenges you to select the largest colored area as fast as possible. Choose one of the four regions before the timer in the upper right finishes counting down, then watch as each square disappears to reveal the winner.</p>
      <p>Continue guessing correctly to build your high score, then keep trying to beat your personal best!</p>
      <p>Think big, act fast, and choose wisely!</p></div>)
  },
  {
    name: "Air Ludo",
    url: "airludo",
    ios: "https://itunes.apple.com/us/app/airludo/id1476354529",
    android: "https://play.google.com/store/apps/details?id=com.durian.ludo",
    bio: (<div><p>Air Ludo is a digital version of the classic Chinese game Aeroplane Chess. <a href="/rules/airludo">Click here for rules.</a></p>
    <p>Crash into opponents, stack up with your other pieces, and take shortcuts to try to get around the board to make it to the center as fast as you can!</p>
    <p>Play against friends locally or the computer. The current player is denoted by the color of the dice block, and the pieces that are able to move will pulse when it's your turn.</p></div>)
  },
  {
    name: "Mines HD",
    url: "mines",
    ios: null,
    android: "https://play.google.com/store/apps/details?id=com.pdonlon.mines2",
    bio: (<div><p>Enjoy a lightweight, fast, and beautiful interpretation of this classic puzzle game with no annoying menus or tedious loading times. Mines HD also features a multiplayer mode where you can test your skills against friends!</p>
      <p>Your goal is to clear the minefield without touching a mine. Opened tiles with numbers printed on them tell you how many bombs are touching that particular tile (1-8). Use strategy to identify potential bombs, then mark them with Flags to avoid opening them.</p>
      <p>Continue to flag bombs and open safe tiles until the minefield is clear. If you do have to guess, pray!</p>
      </div>),
    hidden: true
  },
  {
    name: "Gummy 2048",
    url: "gummy",
    ios: null,
    android:"https://play.google.com/store/apps/details?id=com.vgmoose.gum2048",
    bio: (<div><p>Gummy 2048 is a puzzle game about combining numbered Gummies together to create a high score.</p>
      <p>By sliding tiles around a 4x4 board, you can combine Gummies marked with the same number. When two Gummies combine into one, their number doubles. With every move you make, a new Gummy will randomly appear in an empty space. Keep combining numbers until you get one Gummy with 2048!</p>
      <p>But be careful—the more Gummies you combine, the less space you'll have to move around. Keep an eye on the board and think carefully about which direction to slide the Gummies, or you'll run out of moves!</p>
      <p>For an even greater challenge, try getting a high score on a 3x3 board, or going past 2048 on a board that's 5x5 or 6x6. Every time you reach a new personal best on a different board, the number and the time it took to get it is saved under Score.</p></div>
    ),
    hidden: true
  }
];

export default apps;
