import './App.css';

import { faTwitter } from '@fortawesome/free-brands-svg-icons'
import { faUser, faQuestionCircle, faThLarge, faBars } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { Component } from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';

import AppList from './AppList';

import About from './About';
import AppInfo from './AppInfo';
import Support from './Support';
import Promotion from './Promotion';
import Rules from './Rules';
import PrivacyPolicy from './PrivacyPolicy';


import logo from './durian-header.png';

const nav = [
  { name: 'Apps', icon: faThLarge },
  { name: 'Support', icon: faQuestionCircle },
  { name: 'About', icon: faUser },
  { name: 'Twitter', icon: faTwitter, url: "https://twitter.com/durianapps" }
];

const currentLocation = window.location.pathname.toLowerCase().replace(/\/+$/, "");

class App extends Component {
  render() {
    const navbar = nav.map(
      page =>
      (<li className={`/${page.name.toLowerCase()}` === currentLocation || (currentLocation === '' && page.name === 'Apps') ? 'selected' : 'not-selected'}><div>
        <FontAwesomeIcon icon={page.icon} />
        <a href={page.url ? page.url : `/${page.name.toLowerCase()}`}>{page.name}</a>
      </div>
      </li>));

    const footer =
      (<footer><div className='copyright'>&copy;
        2016 - 2023 <a href="https://newt.llc">Newt Group LLC</a></div>
        <div><a href='/privacy-policy'>Privacy Policy </a>
          <span className="support-footer">{' - '}<a href='/support'>Support</a></span>
        </div>
      </footer>);

    const styles = {
      "/apps": "100%",
      "": "100%",
      "/support": "600px"
    }
    const mainStyle = {
      maxWidth: styles[currentLocation] ? styles[currentLocation] : "700px"
    };

    const toggleMenu = () => {
      const bar = document.getElementsByClassName("navBar");
      if (bar && ["", "none"].includes(bar[0].style.display))
        bar[0].style.display = "block";
      else if (bar)
        bar[0].style.display = "";
    }

    return (
      <BrowserRouter>
        <div className='App'><header className='App-header'>
          <div className='Durian-logo'>
            <a href="/"><img src={logo} className='App-logo' alt='logo' /></a>
          </div>
          <div className="navBarMenu" onClick={toggleMenu}>
            <FontAwesomeIcon icon={faBars} />
          </div>
          <div className="navBar">
            <ul>{navbar}</ul>
          </div>
        </header>

          <div className="main" style={mainStyle}>
            <Switch>
              <Route path='/apps/*/privacy-policy' component={PrivacyPolicy} />
              <Route path='/apps/*' component={AppInfo} />
              <Route path='/apps' component={AppList} />

              <Route path='/about' component={About} />
              <Route path='/rules/*' component={Rules} />
              <Route path='/privacy-policy' component={PrivacyPolicy} />
              <Route path='/support' component={Support} />
              <Route path='/bbarker' component={Promotion} />

              <Route path='/' component={AppList} />
            </Switch>
          </div>

          {footer}
        </div>
      </BrowserRouter>);
  }
}

export default App;
