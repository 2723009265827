import React, {Component} from 'react';

const blurb = (
  <div>
    <h2>Who Are We?</h2>
    <p>We are Durian! Apps, a game development team located in Boston, MA. Our goal is to bring the world fun and fresh gameplay experiences on all devices.</p>
    <p>Known as the "king of fruits", the durian is well known for its thorny exterior and strong scent. Beyond its rough outside, however, the fruit itself is very sweet.</p>

    <h2>About Our Apps</h2>
    <p>The Durian! brand was created alongside our first polished app, <a href="/apps/whoops">Whoops!!</a>, in 2016. Although an early playable demo of the app was running in a few weeks, we would spend many more months fine-tuning different aspects of the game and its appearance before its release in 2017.</p>
    <p>Over time, the base components of Whoops!! became part of a custom game engine named "DurianCore". The engine makes it easy to translate animation and system functionality across iOS and Android devices, allowing us to quickly make fully optimized native apps. Thanks to DurianCore, we were able to make the Whoops!! app just 10MB in size, and lightweight enough to not drain users' batteries while playing.</p>
    <p>Towards the end of 2018 we also began using the <a href="https://godotengine.org">Godot Engine</a> to create cross-platform apps. Godot offers a larger toolkit and more resource-intense capabilities, allowing us to create complex apps. Between DurianCore and the Godot Engine, we have the ability to create apps that are fast, powerful, and accessible to users on all devices.</p>
  </div>
);

class About extends Component {
  render() {
    return blurb;
  }
}

export default About;